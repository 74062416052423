//assets

import './style.scss';

import img from './pug/assets/images/1.png';
import img1 from './pug/assets/images/21.png';
import img2 from './pug/assets/images/Vector-4.png';
import img3 from './pug/assets/images/Vector-3.png';
import img4 from './pug/assets/images/Vector-5.png';
import img5 from './pug/assets/images/Vector-6.png';
import img7 from './pug/assets/images/Vector-7.png';
import img8 from './pug/assets/images/Subtract.png';
import imgGroup20 from './pug/assets/images/Group20.png';
import imgGroup22 from './pug/assets/images/Group22.png';
import imgGroup23 from './pug/assets/images/Group23.png';
import imgGroup24 from './pug/assets/images/Group24.png';

import imgGroup26 from './pug/assets/images/Group26.png';
import imgGroup27 from './pug/assets/images/Group27.png';
import imgGroup28 from './pug/assets/images/Group28.png';

import border from './pug/assets/images/border.png';
import logo from './pug/assets/images/logo.svg';

import img121 from './pug/assets/images/121.png';
import '../node_modules/bootstrap/js/dist/util.js';
import '../node_modules/bootstrap/js/dist/collapse.js';
import IMask from 'imask';
var element = document.getElementById('validate-phone');
var maskOptions = {
  mask: '+{7}(000)000-00-00'
};
var mask = IMask(element, maskOptions);

/*
import UIkit from 'uikit/dist/js/uikit-core.min';
import Countdown from 'uikit/src/js/components/countdown';
UIkit.component('countdown', Countdown);
export default UIkit;

import IMask from 'imask';
var element = document.getElementById('validate-phone');
var maskOptions = {
  mask: '+{7}(000)000-00-00'
};
var mask = IMask(element, maskOptions);
*/
